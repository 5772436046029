'use client';

import { EVENT_CARD_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import { useFragment } from '@apollo/client';
import { EventCardFragment } from '__generated__/graphql';
import React from 'react';
import { EventCard } from '../Cards/EventCard';

export default function EventResult({ id }: { id: string }) {
  // return null;
  const { data } = useFragment({
    fragment: EVENT_CARD_FRAGMENT,
    fragmentName: 'eventCard',
    from: { id, __typename: 'event_Entry' },
  });
  const [result, setResult] = React.useState<EventCardFragment>();

  React.useEffect(() => {
    if (data) setResult(data as EventCardFragment);
  }, [data]);

  if (!result) return null;

  return <EventCard data={result} />;
}
