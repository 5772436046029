'use client';

import { useEntrySearchStore } from '@liquorice/next-search';
import Grid from '../ui/Grid';
import FaqResult from './FaqResult';

export default function FaqResults() {
  const { results } = useEntrySearchStore((s) => s);

  return (
    <Grid rowGutter>
      {results.map((result) => (
        <Grid.Col key={result.id}>
          <FaqResult id={result.id} />
        </Grid.Col>
      ))}
    </Grid>
  );
}
