'use client';

import SearchForm from '@/components/Search/SearchForm';
import Flex from '@/components/ui/Flex';
import { vars } from '@/theme/vars.css';
import CategoryCheckboxes from './CategoryCheckboxes';

const Filters = () => {
  return (
    <Flex
      style={{ borderTop: '1px solid', borderColor: vars.palette.colorSets.primaryTintLight.main }}>
      <CategoryCheckboxes label="Genres" name="genreCategory" />
      <CategoryCheckboxes label="Venues" name="venueCategory" />
      <CategoryCheckboxes label="Programs" name="programCategory" />
      <CategoryCheckboxes label="Event types" name="eventTypeCategory" />
      <CategoryCheckboxes label="Accessibilities" name="accessibilityCategory" />
      <SearchForm cx={{ mT: 'md' }} />
    </Flex>
  );
};

export default Filters;
