'use client';

import EntryMeta, { MetaValues } from '@/components/EntryMeta';
import Box from '@/components/ui/Box';
import Txt from '@/components/ui/Txt';
import { VENUE_CATEGORY_FRAGMENT } from '@/gql/fragments/categories.gql';
import { parseEventInfoFragment } from '@/lib/parsers/common/parseEventInfo';
import { parseEventPriceFragment } from '@/lib/parsers/common/parseEventPrice';
import { parseEventTimeFragment } from '@/lib/parsers/common/parseEventTime';
import { AppGlobals } from '@/lib/parsers/globals';
import { fmtCategoryAddress, simplifyCategory } from '@/lib/utils/format';
import { cleanHtml } from '@/lib/utils/htmlHelpers';
import { EventEntryFragment } from '__generated__/graphql';
import dayjs from 'dayjs';

type EventLayoutSidebarProps = EventEntryFragment & {
  globals?: AppGlobals | null;
};

const EventLayoutSidebar = ({ globals, ...props }: EventLayoutSidebarProps) => {
  const eventTime = parseEventTimeFragment(props.eventTime);
  const eventPrice = parseEventPriceFragment(props.eventPrice);
  const eventInfo = parseEventInfoFragment(props.eventInfo);
  const categories = simplifyCategory(VENUE_CATEGORY_FRAGMENT, props.venueCategory);
  const venue = categories?.map((category) => category.title).join(', ');
  const address = fmtCategoryAddress(props.venueCategory);
  const pricing = cleanHtml(globals?.pricingInformation?.content);

  const meta: MetaValues = [
    eventTime.length > 0
      ? {
          label: 'Performances',
          value: eventTime.map((item) => {
            return (
              <Txt key={item?.eventTimeId} as="p" variant="body" cx={{ mT: 'none', mB: 'none' }}>
                <Txt as="span" style={{ minWidth: '9.375rem', display: 'inline-block' }}>
                  {dayjs(item?.date).format('ddd, DD MMM YYYY')}
                </Txt>
                <Txt as="span">{dayjs(item?.date).format('h:mma')}</Txt>
              </Txt>
            );
          }),
        }
      : undefined,
    eventPrice.length > 0
      ? {
          label: 'Ticket Prices',
          value: (
            <Box>
              {eventPrice.map((item, i) => {
                return (
                  <Txt
                    key={i}
                    as="p"
                    variant="body"
                    cx={{
                      mT: 'none',
                      mB: 'none',
                      display: 'flex',
                      alignItems: 'start',
                      spacing: '2xs',
                    }}>
                    <Txt
                      as="span"
                      style={{ width: '6.875rem', display: 'inline-block', flexShrink: 0 }}>
                      {item?.title}
                    </Txt>
                    <Txt as="span">{item?.price}</Txt>
                  </Txt>
                );
              })}
              {globals?.pricingInformation?.content && (
                <Box cx={{ pT: '2xs' }}>
                  <Txt html variant="small">
                    {pricing}
                  </Txt>
                </Box>
              )}
            </Box>
          ),
        }
      : undefined,
    venue && address
      ? {
          label: 'Venue',
          value: [venue, address],
        }
      : undefined,
    ...eventInfo.map((item, index) => {
      return {
        label: `${item?.heading}`,
        value: [
          <Txt key={index} html>
            {item?.content}
          </Txt>,
        ],
      };
    }),
  ];

  return <EntryMeta cx={{ mY: '3xl' }} metaValues={meta} />;
};

export default EventLayoutSidebar;
