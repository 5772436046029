'use client';

import SearchForm from '@/components/Search/SearchForm';
import Box from '@/components/ui/Box';
import Container from '@/components/ui/Container';
import Grid from '@/components/ui/Grid';
import CategorySelectField from './CategorySelectField';

const Filters = () => {
  return (
    <Box colorSet="primaryTintLighter" paper>
      <Container cx={{ pY: '4xl' }}>
        <Grid>
          <Grid.Col xs={12} md={6}>
            <CategorySelectField allLabel="All Categories" name="faqCategory" />
          </Grid.Col>
          <Grid.Col xs={12} md={6}>
            <SearchForm />
          </Grid.Col>
        </Grid>
      </Container>
    </Box>
  );
};

export default Filters;
