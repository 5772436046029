'use client';

import { GENRE_CATEGORY_FRAGMENT } from '@/gql/fragments/categories.gql';
import { ENTRY_BASE_FRAGMENT } from '@/gql/fragments/entries.gql';
import { parseEventTimeFragment } from '@/lib/parsers/common/parseEventTime';
import useShortlistStore from '@/lib/store/shortlist';
import { fmtEventTime, simplifyCategory } from '@/lib/utils/format';
import { parseUri, toString } from '@liquorice/utils';
import { getFragmentData } from '__generated__';
import { EventCardFragment } from '__generated__/graphql';
import { useTranslations } from 'next-intl';
import Card from '../Card';
import { OverrideCardProps } from '../EntryCards';
import PageTicket from '../PageTicket';
import Box from '../ui/Box';
import Btn from '../ui/Btn';
import * as styles from './Cards.css';

export const EventCard = ({
  data,
  overrides,
}: {
  data?: EventCardFragment;
  overrides?: OverrideCardProps;
}) => {
  const { entryImage, entryTitle, eventTime, status, ...base } = data ?? {};
  const {
    heading: maybeTitle,
    image: maybeImage,
    // content:maybeContent
  } = overrides ?? {};

  const { uri, id } = getFragmentData(ENTRY_BASE_FRAGMENT, base);

  const href = parseUri(uri);

  const t = useTranslations('common');
  const genres = simplifyCategory(GENRE_CATEGORY_FRAGMENT, data?.genreCategory);
  const dateRange = fmtEventTime(parseEventTimeFragment(eventTime));
  const toggleEvent = useShortlistStore((s) => s.toggleEvent);
  const disabled = status === 'expired';

  if (!data) return null;

  return (
    <Card
      className={styles.root}
      disableLinkWrap
      elevation={false}
      fullHeight
      colorSet="white"
      paper
      item={{
        href,
        title: maybeTitle ?? entryTitle,
        image: maybeImage ?? entryImage,
        meta: genres?.map((genre) => genre.title).join(', '),
        description: dateRange,
        disabled,
      }}>
      <Box cx={{ position: 'relative' }}>
        <Card.Meta className={styles.tag} color="primary" monoFont uppercase variant="tiny" />
        <Card.Heart
          itemID={toString(id)}
          onClick={() => toggleEvent(data)}
          className={styles.heart}
          cx={{ m: '3xs' }}
        />
        <Card.Disabled
          className={styles.disabled}
          color="white"
          monoFont
          uppercase
          variant="tiny"
        />
        <Card.Image ratio="landscape" enableLinkWrap />
      </Box>
      <Card.Body className={styles.body({ withTransition: !disabled })} colorSet="white" paper>
        <Card.Title
          as="div"
          variant="h6"
          cx={{ fontFamily: 'body', fontWeight: 'bold', mY: '4xs' }}
        />
        <Card.Description as="span" variant="tiny" monoFont weight="regular" uppercase />
        <Box colorSet="white" paper className={styles.cta}>
          {href && (
            <Box cx={{ width: '40' }}>
              <Btn
                as="a"
                href={href}
                variant="outlined"
                color="primary"
                size="tiny"
                weight="bold"
                fullWidth>
                {t('moreInfo')}
              </Btn>
            </Box>
          )}
          <PageTicket
            cx={{ width: 'full' }}
            // parentEntry={item}
            TicketBtnProps={{
              color: 'primary',
              size: 'tiny',
              fullWidth: true,
              weight: 'bold',
            }}
          />
        </Box>
      </Card.Body>
    </Card>
  );
};
