'use client';

import Box from '@/components/ui/Box';

import { ENTRY_BASE_FRAGMENT } from '@/gql/fragments/entries.gql';
import { firstNonNullable, parseUri } from '@liquorice/utils';
import { getFragmentData } from '__generated__';
import { EntryCardsFragment } from '__generated__/graphql';
import Card from '../Card';
import { useEntryCards } from '../EntryCards/useEntryCards';
import FaqCard from '../FaqCard';
import * as styles from './Cards.css';

export const SearchCard = (data: EntryCardsFragment) => {
  const { fragment, typeLabel } = useEntryCards(data) ?? {};
  const isFAQ = fragment?.__typename === 'faq_Entry';

  const base = getFragmentData(ENTRY_BASE_FRAGMENT, fragment);
  const href = parseUri(base?.uri);

  if (isFAQ) {
    return <FaqCard {...fragment} />;
  }

  const { entrySummary, entryImage, entryTitle } = fragment ?? {};

  return (
    <Card
      elevation={false}
      colorSet="white"
      paper
      item={{
        href,
        title: entryTitle,
        image: entryImage,
        description: entrySummary,
        meta: typeLabel,
      }}>
      {base?.sectionHandle !== 'page' && (
        <Box cx={{ position: 'relative' }}>
          {!!firstNonNullable(entryImage) && (
            <Card.Meta className={styles.tag} color="primary" monoFont uppercase variant="tiny" />
          )}
          <Card.Image ratio="landscape" />
        </Box>
      )}
      <Card.Body cx={{ rowGap: 'xs', mY: 'xs' }}>
        <Card.Title variant="h5" as="span" />
        <Card.Description variant="small" />
        <Card.Cta
          variant="text"
          size="small"
          EndIconProps={{ name: 'chevronRight' }}
          cx={{ fontSize: 'medium' }}
        />
      </Card.Body>
    </Card>
  );
};
