'use client';

import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { useSearchInputs } from '@liquorice/next-search';
import IconButton from '../IconButton';
import Box, { BoxProps } from '../ui/Box';
import * as styles from './SearchForm.css';

type SearchFormProps = BoxProps;

export default function SearchField(props: SearchFormProps) {
  const { inputProps, buttonProps } = useSearchInputs();

  return (
    <Box {...mergePropsClassName(props, styles.searchForm)}>
      <Box
        className={styles.searchInput}
        as="input"
        colorSet="white"
        paper
        bordered
        rounded
        cx={{ fontSize: 'medium', pX: 'md', pY: 'xs' }}
        placeholder="Search..."
        {...inputProps}
      />
      <Box className={styles.searchButton}>
        <IconButton
          {...buttonProps}
          icon="search"
          cx={{ m: 'sm' }}
          color="primary"
          variant="text"
        />
      </Box>
    </Box>
  );
}
