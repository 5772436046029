'use client';

import { EVENT_CARD_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import { EventCard as Card } from '../Cards/EventCard';
import createCard from './createCard';

const EventCard = createCard(EVENT_CARD_FRAGMENT, ({ data, overrides, ...props }) => {
  if (!data) return null;

  return <Card {...{ data, overrides }} />;
});

export default EventCard;
