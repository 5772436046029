'use client';

import { ENTRY_CARDS_FRAGMENT, FAQ_CARD_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import { withFragment } from '@liquorice/gql-utils';
import { getFragmentData } from '__generated__';
import { SearchCard as Card } from '../Cards/SearchCard';
import FaqCard from '../FaqCard';

const SearchCard = withFragment(ENTRY_CARDS_FRAGMENT, (data) => {
  if (!data) return null;

  if (data?.__typename === 'faq_Entry') {
    const faqData = getFragmentData(FAQ_CARD_FRAGMENT, data);
    return <FaqCard {...faqData} />;
  }

  return <Card {...data} />;
});

export default SearchCard;
