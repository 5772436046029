'use client';

import Box from '@/components/ui/Box';
import { vars } from '@/theme/vars.css';
import { UseCategoriesOptions, useCategoryChoice } from '@liquorice/next-search';
import React from 'react';

export interface CategorySelectFieldProps extends UseCategoriesOptions {
  allLabel?: string;
}

const CategorySelectField = ({ allLabel = 'All', ...config }: CategorySelectFieldProps) => {
  const { options, ...choice } = useCategoryChoice(config);

  const handleChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    const selected = Array.from(ev.target.selectedOptions).map((option) => option.value);
    choice.setValue(selected);
  };

  return (
    <Box
      colorSet="white"
      paper
      as="select"
      aria-label={config.name}
      name={choice.name}
      multiple={choice.multiple}
      value={choice.value}
      onChange={handleChange}
      style={{ borderColor: vars.palette.colorSets.primaryTintLight.main }}
      cx={{ p: 'xs', width: '100', height: '100', borderRadius: 'xs' }}>
      <Box as="option" value="">
        {allLabel}
      </Box>
      {options?.map((option) => (
        <Box as="option" key={option.value} value={option.value}>
          {option.label}
        </Box>
      ))}
    </Box>
  );
};

export default CategorySelectField;
