'use client';

import { useEntrySearchStore } from '@liquorice/next-search';
import Grid from '../ui/Grid';
import EventResult from './EventResult';

export default function EventResults() {
  const { results } = useEntrySearchStore((s) => s);

  return (
    <Grid cx={{ rowGap: 'xl' }}>
      {results.map((result, index) => (
        <Grid.Col key={index} md={6} lg={4}>
          <EventResult id={result.id} />
        </Grid.Col>
      ))}
    </Grid>
  );
}
