'use client';

import { FAQ_CARD_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import { useFragment } from '@apollo/client';
import { FaqCardFragment } from '__generated__/graphql';

import React from 'react';
import FaqCard from '../FaqCard';

export default function FaqResult({ id }: { id: string }) {
  // return null;
  const { data } = useFragment({
    fragment: FAQ_CARD_FRAGMENT,
    fragmentName: 'faqCard',
    from: { id, __typename: 'faq_Entry' },
  });
  const [result, setResult] = React.useState<FaqCardFragment>();

  React.useEffect(() => {
    if (data) setResult(data as FaqCardFragment);
  }, [data]);

  if (!result) return null;

  return <FaqCard {...result} />;
}
