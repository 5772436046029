'use client';

import Box from '@/components/ui/Box';
import Flex from '@/components/ui/Flex';
import UnstyledAccordion from '@/components/UnstyledAccordion';
import UnstyledAccordionItem from '@/components/UnstyledAccordion/UnstyledAccordion.Item';
import UnstyledAccordionItemBody from '@/components/UnstyledAccordion/UnstyledAccordion.ItemBody';
import UnstyledAccordionItemCaret from '@/components/UnstyledAccordion/UnstyledAccordion.ItemCaret';
import UnstyledAccordionItemHandle from '@/components/UnstyledAccordion/UnstyledAccordion.ItemHandle';
import UnstyledAccordionItemLabel from '@/components/UnstyledAccordion/UnstyledAccordion.ItemLabel';
import { vars } from '@/theme/vars.css';
import { UseCategoriesOptions, useCategoryChoice } from '@liquorice/next-search';
import { Checkbox } from '@mui/material';

export interface CategoryCheckboxesProps extends UseCategoriesOptions {
  label: string;
}

const CategoryCheckboxes = ({ label, ...config }: CategoryCheckboxesProps) => {
  const { options, name, isSelected, toggleValue } = useCategoryChoice({
    multiple: true,
    ...config,
  });

  if (options.length === 0) return null;

  return (
    <UnstyledAccordion>
      <UnstyledAccordionItem
        index={1}
        style={{ borderColor: vars.palette.colors.primaryTintLight }}>
        <UnstyledAccordionItemHandle index={1}>
          <UnstyledAccordionItemLabel underline="none" weight="bold" color="primary" variant="text">
            {label}
          </UnstyledAccordionItemLabel>
          <UnstyledAccordionItemCaret index={1} />
        </UnstyledAccordionItemHandle>
        <UnstyledAccordionItemBody index={1}>
          <Flex spacing="5xs" cx={{ pX: '3xs' }}>
            {options?.map((option) => (
              <Box as="label" key={option.value}>
                <Checkbox
                  sx={{
                    '&.MuiCheckbox-root': {
                      color: vars.palette.colors.primary,
                    },
                    '&.Mui-checked': {
                      color: vars.palette.colors.primary,
                    },
                  }}
                  name={name}
                  value={option.value}
                  checked={isSelected(option.value)}
                  onChange={() => toggleValue(option.value)}
                />
                {option.label}
              </Box>
            ))}
          </Flex>
        </UnstyledAccordionItemBody>
      </UnstyledAccordionItem>
    </UnstyledAccordion>
  );
};

export default CategoryCheckboxes;
